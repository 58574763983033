<template>
  <b-card>
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <ValidationObserver
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          @submit.prevent="handleSubmit(createAddons)"
        >
          <b-row>
            <b-card
              title="Addon Details"
              class="mb-0 w-100"
            >
              <b-row>
                <b-col md="3">
                  <b-form-group
                    label="Addon Name"
                    label-for="addon-name"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        id="addon-name"
                        v-model="form.addon_name"
                        placeholder="Enter Addon Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
                <b-col md="9">
                  <b-form-group
                    label="Addon Description"
                    label-for="addon-description"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-input
                        id="addon-description"
                        v-model="form.addon_description"
                        placeholder="Enter Addon Description"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <!-- submit -->
                <b-col class="d-flex justify-content-end">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mt-1"
                  >
                    Submit
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
    <b-row>
      <b-col>
        <b-overlay
          id="overlay-background"
          :show="loading"
          blur="10px"
          opacity="0.30"
          rounded="sm"
          variant="light"
        >
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="rows"
            :fields="fields"
            show-empty
          >
            <template #cell(addon_name)="data">
              {{ data.value }}
            </template>
            <template #cell(addon_description)="data">
              {{ data.value }}
            </template>
            <template #cell(is_active)="data">
              <b-badge
                :variant="`light-${data.value === true ? 'success' : 'danger'}`"
              >
                {{ data.value === true ? 'Active' : 'Inactive' }}
              </b-badge>
            </template>
            <template #cell(created_at)="data">
              {{ __dateTimeFormatter(data.value) }}
            </template>
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                :class="rows.length !== 1 ? 'dropdown-no-scroll':''"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="12"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  @click="editAddon(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  Edit
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-overlay>
        <div class="mx-2 mb-2">
          <b-row
            cols="12"
          >
            <b-col
              class="d-flex align-items-center justify-content-sm-start"
              md="3"
            >
              <label class="width-75 mr-1">Per page</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block width-100"
              />
            </b-col>
            <!-- Pagination -->
            <b-col
              class="d-flex align-items-center justify-content-sm-end"
              md="9"
            >
              <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
              <b-pagination
                v-model="page"
                :per-page="perPage"
                :total-rows="(meta.total)? meta.total : 0"
                class="mb-0 mt-1 mt-sm-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <EditAddonModal
      ref="edit_addon"
      @content-updated="getAddonList"
    />
  </b-card>
</template>
<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BCard, BOverlay, BButton, BTable, BBadge, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import EditAddonModal from '@/components/Addons/EditAddonModal.vue'

const AddonRepository = RepositoryFactory.get('addon')
// const ResourceRepository = RepositoryFactory.get('resource')
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BOverlay,
    BButton,
    BTable,
    EditAddonModal,
    BBadge,
    vSelect,
    BPagination,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      total: 0,
      meta: {},
      loading: false,
      sort: '',
      value: 25,
      perPage: 20,
      pageOptions: [10, 20, 50],
      page: 1,
      filters: {},
      rows: [],
      form: {},
      fields: [
        {
          key: 'addon_name',
          label: 'Addon Name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'addon_description',
          label: 'Description',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'is_active',
          label: 'Status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'Created At',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
        },
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.getAddonList()
      },
    },
    page() {
      this.getAddonList()
    },
    perPage() {
      this.getAddonList()
    },
  },
  mounted() {
    this.getAddonList()
  },
  methods: {
    async createAddons() {
      this.loading = true
      try {
        const res = await AddonRepository.createAddon(this.form)
        if (res.status === 200) {
          this.showSuccessMessage('Addon Created Successfully')
          const currentList = this.getAddonList()
          const newAddon = res.data
          currentList.unshift(newAddon)

          this.setaddonlist(currentList)
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getAddonList() {
      this.loading = true
      try {
        // eslint-disable-next-line no-underscore-dangle
        // this.filterQuery = this.__refineDateQuery(this.filterQuery, ['filter[created_at]'])
        const { data } = (await AddonRepository.getAddonList(this.page, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    editAddon(id) {
      this.$refs.edit_addon.openModal(id)
    },
    handleChangePage(page) {
      this.page = page
      this.getAddonList()
    },
  },
}
</script>
