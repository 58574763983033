<template>
  <div>
    <b-modal
      id="create-tenant-subscription-modal"
      ref="modal"
      v-model="open"
      hide-footer
      no-close-on-backdrop
      size="lg"
      title="EDIT ADDON"
      centered
    >
      <b-overlay
        id="overlay-background"
        :show="loading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-form
          class="my-8"
          @submit.prevent="handleSubmit()"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                class="required"
                label="Addon Name"
                label-for="addon_name"
              >
                <b-form-input
                  v-model="form.addon_id"
                  hidden="true"
                  required
                />
                <b-form-input
                  v-model="form.addon_name"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                class="required"
                label="Addon Description"
                label-for="addon_description"
              >
                <b-form-input
                  v-model="form.addon_description"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col
              class="d-flex justify-content-end align-items-center mb-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="w-100"
                type="submit"
                variant="primary"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </b-overlay>
    </b-modal>

  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BCol,
  BFormGroup,
  BForm,
  BRow,
  BOverlay,
  VBModal,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const AddonRepository = RepositoryFactory.get('addon')

function initialState() {
  return {
    open: false,
    data: {},
    loading: false,
    tenants: [],
    form: {},
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BCol,
    BFormGroup,
    BForm,
    BRow,
    BOverlay,
    BFormInput,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(data) {
      this.open = true
      this.form.addon_name = data.addon_name
      this.form.addon_description = data.addon_description
      this.form.addon_id = data.id
    },
    async handleSubmit() {
      try {
        this.loading = true
        const res = await AddonRepository.updateAddon(this.form, this.form.addon_id)
        if (res.status === 200) {
          this.open = false
          this.showSuccessMessage('Addon Updated Successfully')
          this.$emit('content-updated')
        } else {
          this.showErrorMessage('Cannot Update Addon')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>
<style scoped>

</style>
